.MFADetailForm {
  /* text-align: center; */
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  padding: 1em;
  text-align: left;
  width: 700px;
}

.trash-icon {
  color: #006bd3;
  cursor: pointer;
}

.mfaMethodCard {
  background-color: #d9ebf0;
}

.formHeader {
  /* padding: 1em; */
  font-size: calc(1.3rem + .6vw);
  font-weight: 500;
  text-align: left;
  color: darkslategray;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: .5em;
  margin-bottom: 1em;
}