.AccountLockoutForm {
  /* text-align: center; */
  /* border: 2px solid #d3d3d3; */
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 2em; */
  padding: 2em;
  text-align: left;
  width: auto;
}

.copy-icon {
  cursor: pointer;
}

.form-title {
  /* marginTop: "2em"; */
  /* marginLeft: 'auto';  */
  /* marginRight: 'auto';  */
  /* width: '700px'; */
  font-size: '200pt' !important;
  color: 'blue' !important;
}
